import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import { fundTransfer, getOptionSubAdmin, getUserPayment, restRecord } from "../../reducers/commonReducer";
import moment from 'moment';
import UserPaymentAction from "./UserPaymentAction";
import SideBaar from "../../Components/SideBaar";

const UserPaymentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [status, setStatus] = useState();
  const [userName, setUserName] = useState();
  const [amount, setAmount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [subadmin, setSubadmin] = useState();
 
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(getUserPayment({currentPage, end:endDate, start:startDate,}))
    if(localStorage.getItem("userRole") === "1"){
      dispatch(getOptionSubAdmin());
    }
  }, [dispatch, navigate]);
  const { getUserPaymentList, transfertime, getOptionSubAdminList, getUserPaymentBlank, totalAmount} = useSelector((state) => state.commonReducer);
  const handleSubmit = (e) => {
    dispatch(restRecord())
    dispatch(getUserPayment({
      amount, end:endDate, start:startDate, status, username: userName, subadmin
    }))
  }
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />  
      <div className="container">
        <Row className="mt-0 mb-2 align-items-center bg-warning py-2">
          <Col md={6}>
          <Button variant="success" className="mx-2 py-1" onClick={async() => {
            dispatch(fundTransfer())
          }}>Fund Transfer</Button>
          </Col>
          <Col md={6}>
          <p className="float-right"><b>Last Transfer Time: {moment(transfertime).format('DD/MM/YYYY HH:mm:ss')}</b></p>
          </Col>
        </Row>
        <Row className="mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">User Payment</h3>
          </Col>
          <Col md={6}>
          <p className="mb-0 float-right"><b>Total Amount : {totalAmount}</b></p>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <Row className="mb-2 user-table">
          <Col md={2}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>UserName/Tran Id</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Enter Date"
              step="1"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Col>
          <Col md={1}>
            <label>
              <b>Amount</b>
            </label>
            <input
              value={amount}
              onChange={(e) => setAmount(e.target.value.trim())}
              type="text"
              className="form-control"
            />
          </Col>
          <Col md={2}>
            <label>
              <b>Status</b>
            </label>
            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} aria-label="Default select example">
              <option value="">Select Status</option>
              <option value="0">Pending</option>
              <option value="1">Failed</option>
              <option value="2">Succeed</option>
            </Form.Select>
          </Col>
          <Col md={2}>
            {
               localStorage.getItem("userRole") === "1" && <>
                <label>
                  <b>SubAdmin</b>
                </label>
                <Form.Select value={subadmin} onChange={(e) => setSubadmin(e.target.value)} aria-label="Default select example">
                  <option value="">Select SubAdmin</option>
                  {
                    !!getOptionSubAdminList && getOptionSubAdminList?.map((data, index) => <option key={index} value={data?.id}>{data?.username}</option>)
                  }
                </Form.Select>
               </>
            }
          </Col>
          <Col md={1}>
            <Button className="mt-4 w-50 p-1" variant="success" type="submit" onClick={handleSubmit}>
            <i className="bi bi-search"></i>
            </Button>
            <Button className="mt-4 w-50 p-1" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
            <i className="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>User Name</th>
                <th>Name</th>
                <th>Payment ID</th>
                {/* <th>Img</th> */}
                <th>Day</th>
                <th>Reason</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!!getUserPaymentList &&
                getUserPaymentList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.userid?.username}</td>
                      <td>{data?.userid?.name}</td>
                      <td>{data?.id}</td>
                      {/* <td>{data?.paymentImage && <Button onClick={() => openInNewTab(data?.paymentImage)}>Open Img</Button>}</td> */}
                      <td>{moment(data.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                      <td>{data?.reason}</td>
                      <td>{data?.amount}</td>
                      <td>{(data?.txn_status === 0 || data?.txn_status === 1) ? 
                      <UserPaymentAction
                        id={data?.id}
                        txn_status={data?.txn_status}
                        amount={data?.amount}
                        reason={data?.reason}
                      /> : 
                      <Button variant="success">Success</Button>}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!getUserPaymentBlank && getUserPaymentBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getUserPayment({currentPage:currentPage + 1, subadmin, 
                amount, end:endDate, start:startDate, status, username: userName
              }))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div> 
      </Col>
      </Row>
    </>
  );
};

export default UserPaymentList;
