import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InActiveUsers, restRecord } from "../../reducers/commonReducer";
import moment from 'moment';
import SideBaar from "../../Components/SideBaar";
import { Button } from "react-bootstrap";
 
const InactiveUserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(InActiveUsers(currentPage))
  }, [dispatch, navigate]);
  const {inActiveUsersList, inActiveUsersBlank} = useSelector((state) => state.commonReducer);

  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">Inactive Users</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>User Name</th>
                <th>Points</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {!!inActiveUsersList &&
                inActiveUsersList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.username}</td>
                      <td>{data?.coins.toFixed(2)}</td>
                      <td>{moment(data.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!inActiveUsersBlank && inActiveUsersBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(InActiveUsers(currentPage + 1))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default InactiveUserList;
