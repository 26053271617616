import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import Header from "../../Components/Header";
import { getTransactions, restRecord } from "../../reducers/commonReducer";
import moment from 'moment';
import SideBaar from "../../Components/SideBaar";
import AutoFillDate from "../../AutoFillDate";

const TransactionsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [status, setStatus] = useState();
  const [userName, setUserName] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [day, setDay] = useState();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(getTransactions({end:endDate, start:startDate,}))
  }, [dispatch, navigate]);
  const handleSubmit = (e) => {
    dispatch(getTransactions({
       end:endDate, start:startDate, type:status, username: userName, day
    }))
  }
  const { getTransactionsList, getTransactionsBlank} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={6}>
            <h3 className="mb-0">Transactions</h3>
          </Col>
        </Row>
        <hr className="mt-0"/>
        <Row className="mb-2 user-table">
          <Col md={2}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <AutoFillDate setendDate={setendDate} setstartDate={setstartDate} day={day} setDay={setDay}/>
          </Col>
          <Col md={2}>
            <label>
              <b>UserName</b>
            </label>
            <Form.Control
              type="text"
              placeholder="Enter UserName"
              step="1"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>TXN Type</b>
            </label>
            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} aria-label="Default select example">
              <option value="">Select TXN Type</option>
              <option value="ADD">ADD</option>
              <option value="SUBTRACT">SUBTRACT</option>
              <option value="WITHDRAW">WITHDRAW</option>
            </Form.Select>
          </Col>
          <Col md={2}>
            <Button className="mt-4 w-50" variant="success" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="mt-4 w-50" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
              Clear
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>User Name</th>
                <th>Type</th>
                <th>Date</th>
                <th>New Points</th>
                <th>In</th>
                <th>Out</th>
                <th>Old</th>
              </tr>
            </thead>
            <tbody>
              {!!getTransactionsList &&
                getTransactionsList?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                    <td>
                      {data?.toUser?.username ? data?.toUser?.username : data?.fromUser?.name}
                    </td>
                    <td>{data?.type}</td>
                    <td>{moment(data?.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{data?.remaining_coins.toFixed(2)}</td>
                    <td>{data.type === 'ADD' ? data?.trans_coins : ''}</td>
                    <td>
                      {data.type === 'SUBTRACT' || data.type === 'WITHDRAW'
                        ? data?.trans_coins
                        : ''}
                    </td>
                    <td>
                      {data.type === 'SUBTRACT' || data.type === 'WITHDRAW'
                        ? (data?.remaining_coins + data?.trans_coins).toFixed(2)
                        : (data?.remaining_coins - data?.trans_coins).toFixed(2)}
                    </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!getTransactionsBlank && getTransactionsBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(getTransactions({currentPage:currentPage + 1, end:endDate, start:startDate, type:status, username: userName, day}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default TransactionsList;
