import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Table, Col, Button } from "react-bootstrap";
import Header from "../../../Components/Header";
import { colorGameHistory, redBlackGameHistory, restRecord } from "../../../reducers/commonReducer";
import moment from "moment";
import SideBaar from "../../../Components/SideBaar";

const RedBlackGameHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord());
    dispatch(redBlackGameHistory({ page }));
  }, []);

  const { redBlackGameList, redBlackHistoryBlank } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <Row>
        <Col md={2} className="p-0">
          <SideBaar />
        </Col>
        <Col md={10}>
          <Header />
          <div className="container">
            <div className="overflow-auto mt-4">
              <Table
                striped
                bordered
                hover
                size="sm"
                className="overflow-scroll"
              >
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Number</th>
                    <th>Start</th>
                    <th>Total Betting</th>
                    <th>Total Winning</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!!redBlackGameList &&
                    redBlackGameList?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{data?.number}</td>
                          <td>
                            {moment(data.start).format("DD/MM/YYYY HH:mm:ss")}
                          </td>
                          <td>{data?.total_betting}</td>
                          <td>{data?.total_winning}</td>
                          <td><Link to={`/red-black-current-game?id=${data?.id}`}><Button variant="success">View</Button></Link></td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {!!redBlackHistoryBlank && redBlackHistoryBlank?.length < 1 ? (
                <h3 className="text-center text-danger">Not Found</h3>
              ) : (
                <Button
                  onClick={() => {
                    dispatch(
                      colorGameHistory({ page: page + 1 })
                    );
                    setPage(page + 1);
                  }}
                  className="w-100"
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RedBlackGameHistory;
