import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import Header from "../../Components/Header";
import { gameHistory, getOptionSubAdmin, restRecord } from "../../reducers/commonReducer";
import moment from 'moment';
import SideBaar from "../../Components/SideBaar";
import AutoFillDate from "../../AutoFillDate";

const GameHistory = () => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().format('YYYY-MM-DDT23:59:59'));
  const [subadmin, setSubadmin] = useState();
  const [gamename, setGamename] = useState();
  const [day, setDay] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    dispatch(gameHistory({currentPage, end:endDate, start:startDate,}))
    if(localStorage.getItem("userRole") === "1"){
      dispatch(getOptionSubAdmin());
    }
  }, [dispatch, navigate]);

  const handleSubmit = (e) => {
    dispatch(restRecord())
    dispatch(gameHistory({
       end:endDate, start:startDate, gamename, day, subadmin, currentPage
    }))
  } 
  const { gameHistoryList, gameHistoryDetails, getOptionSubAdminList, gameHistoryBlank} = useSelector((state) => state.commonReducer);
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <Col md={4}>
            <h3 className="mb-0">Game History</h3>
          </Col>
          <Col md={4}>
            <h3 className="mb-0">Total Betting: {gameHistoryDetails?.total_betting}</h3>
          </Col>
          <Col md={4}>
            <h3 className="mb-0">Total Winning: {gameHistoryDetails?.total_winning}</h3>
          </Col>
        </Row>
        <hr className="m-0"/>
        <Row className="mb-2 user-table">
          <Col md={2}>
            <label htmlFor="date">
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              id="date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <AutoFillDate setendDate={setendDate} setstartDate={setstartDate} day={day} setDay={setDay}/>
          </Col>
          <Col md={2}>
            {
              localStorage.getItem("userRole") === "1" && <>
              <label>
                <b>SubAdmin</b>
              </label>
              <Form.Select value={subadmin} onChange={(e)=> setSubadmin(e.target.value)}>
                  <option value="">Select SubAdmin</option>
                  {
                    !!getOptionSubAdminList && getOptionSubAdminList?.map((data, index) =>  <option key={index} value={data?.id}>{data?.username}</option>)
                  }
              </Form.Select>
              </>
            }
          </Col>
          <Col md={2}>
              {/* <label>
                <b>Game</b>
              </label>
              <Form.Select value={gamename} onChange={(e)=> setGamename(e.target.value)}>
                  <option value="">Select Game</option>
              </Form.Select> */}
          </Col>
          <Col md={2}>
            <Button className="mt-4 w-50" variant="success" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button className="mt-4 w-50" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
              Clear
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Game ID</th>
                <th>Total Playing</th>
                <th>Total Winning</th>
                <th>Start</th>
                <th>End</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!!gameHistoryList &&
                gameHistoryList?.map((data, index) => {
                let startDate = new Date(data.start);
                let endDate = new Date(data.end);
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                    <td>
                        <Link to={`/numberprediction?gameid=${data?.id}`}>{data?.id.substr(-6)}</Link>
                    </td>
                    <td>{data?.total_betting.toFixed(2)}</td>
                    <td>{data?.total_winning.toFixed(2)}</td>
                    <td>{moment(startDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>{moment(endDate).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td>
                        <Button variant="success" onClick={() => navigate(`/user-game-history?userid=${data?.id}`)} className="setting-btn m-1 px-2 py-0">
                            <i className="fa fa-user" aria-hidden="true"></i> Users
                        </Button>
                        <Button variant="warning" onClick={() => navigate(`/game-transactions?gameid=${data?.id}`)} className="setting-btn m-1 px-2 py-0">
                            <i className="fa fa-exchange" aria-hidden="true"></i> Transactions
                        </Button>
                    </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {
            !!gameHistoryBlank && gameHistoryBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              dispatch(gameHistory({currentPage:currentPage + 1, end:endDate, start:startDate, gamename, day, subadmin}))
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          }
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default GameHistory;
